<template>    
    <v-card>
        <!-- Titulo del dialogo -->
        <v-card-title>
            Crear Procedencia
        </v-card-title>
        <v-divider></v-divider>
            <!-- Formulario -->
        <v-form ref="form">
            <v-row class="py-3 px-6">
                <v-col cols="12" sm="6" md="4">
                    <v-text-field :rules="[v => !!v || 'Campo requerido']" v-model="editedItem.nombre" label="Nombre"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field :rules="[v => !!v || 'Campo requerido']" v-model="editedItem.valor" type="number" label="Valor"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-select :rules="[v => !!v || 'Campo requerido']" v-model="editedItem.categoria" :items="categorias" label="Categoría"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true" v-on:vdropzone-success="uploadSuccess" v-on:vdropzone-error="uploadError" v-on:vdropzone-removed-file="fileRemoved"/>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                    <v-textarea :rules="[v => !!v || 'Campo requerido']" v-model="editedItem.descripcion" label="Descripción"></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-card-actions class="pt-4">
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="blue darken-1" text :disabled="!valid" @click="save">Guardar</v-btn>
                    </v-card-actions>
                </v-col>
            </v-row>
        </v-form>
    </v-card>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import axios from "axios";
export default {
    components: {
        vueDropzone: vue2Dropzone,
    }, 
    data:()=>({  
        dropzoneOptions: {
            url: "https://torstdio.site/api/v1/producto/files",
            addRemoveLinks: true,
            maxFiles: 1
        },
        valid: true,
        editedItem: {
            nombre:'',
            valor:'',
            sucursales:[
                {"sucursal":1, "disponible":1},
                {"sucursal":2, "disponible":1},
                {"sucursal":3, "disponible":1},
                {"sucursal":4, "disponible":1},
                {"sucursal":5, "disponible":1},
                {"sucursal":6, "disponible":1},
                {"sucursal":7, "disponible":1}
            ],
            descripcion:'',
            categoria:'',
            foto:'',
            descuento:'1',
        },
        rules: {
            required: value => !!value || 'Campo requerido',
        },
    }),
    computed:{
        categorias(){
            return [
                'complementos', 
                'bebidas', 
                'comida'
            ]
        }
    },
    methods:{
        uploadSuccess(file, response) {
            this.editedItem.foto = response.file;
        },
        uploadError(file, message) {
        },
        fileRemoved() {
            
        },
        close () {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.$nextTick(() => {
                this.$emit("closeCreateDialogProducto", false);
            })
        },
        save () {
            axios.post("https://torstdio.site/api/v1/producto/guardar",Object.assign(this.editedItem)).then(response=>{
                this.close()
            })
        },
    },
}
</script>