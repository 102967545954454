var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ma-12"},[_c('v-data-table',{staticClass:"elevation-0 px-6 py-4",attrs:{"headers":_vm.headers,"items":_vm.productos,"sort-by":"created_at"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Productos")])],1)]},proxy:true},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.valor.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.sucursales",fn:function(ref){
var item = ref.item;
return [(_vm.disponible(item.sucursales)==1)?_c('v-icon',{on:{"click":function($event){return _vm.update(item, 0)}}},[_vm._v("mdi-check")]):_vm._e(),(_vm.disponible(item.sucursales)==0)?_c('v-icon',{on:{"click":function($event){return _vm.update(item, 1)}}},[_vm._v("mdi-close")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"font-size":"13px"}},[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Editar ")],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Eliminar ")],1)],1)],1)]}},{key:"no-data",fn:function(){return [_vm._v(" No existen registros de productos aún ")]},proxy:true},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"bottom":"","color":"#f60e15","dark":"","fab":"","fixed":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('create',{on:{"closeCreateDialogProducto":_vm.closeCreateDialogProducto}})],1),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('edit',{attrs:{"producto":_vm.producto},on:{"closeDialogEditProducto":_vm.closeDialogEditProducto}})],1),_c('div',{staticClass:"text-center"},[_c('v-bottom-sheet',{attrs:{"inset":""},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"150px"}},[_c('div',{staticClass:"pt-6"},[_vm._v(" ¿Seguro que deseas borrar esta producto? ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deleteProducto()}}},[_vm._v(" Eliminar ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"grey"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }